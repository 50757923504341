var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":"0.6","persistent":"","max-width":"600","value":_vm.showDialog}},[_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-package-variant-closed")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"25px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.nc ? _vm.$t("nc-custom-product-title") : _vm.$t("custom-product-title")))]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[(!_vm.sending_changes)?_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")]):_vm._e()],1)],1)]},proxy:true}])},[_c('div',{staticClass:"vertical-spacer"}),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [(_vm.loadingMeasureUnits)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"primary"}}):_vm._e(),(!_vm.loadingMeasureUnits)?_c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":_vm.$t('product-edit-hint-name'),"outlined":"","dense":"","error-messages":errors,"success":valid},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required|min_value:0.001"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":_vm.$t('product-edit-hint-quantity'),"outlined":"","dense":"","number":""},on:{"keypress":function($event){return _vm.isQuantityNumber($event)}},model:{value:(_vm.product.quantity),callback:function ($$v) {_vm.$set(_vm.product, "quantity", $$v)},expression:"product.quantity"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":true,"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":_vm.$t('product-edit-hint-price'),"error-messages":errors,"success":valid,"required":"","outlined":"","type":"number","dense":""},model:{value:(_vm.product.unit_price),callback:function ($$v) {_vm.$set(_vm.product, "unit_price", $$v)},expression:"product.unit_price"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":true,"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":_vm.$t('product-edit-hint-vat'),"error-messages":errors,"success":valid,"required":"","outlined":"","type":"number","dense":""},model:{value:(_vm.product.vat_percent),callback:function ($$v) {_vm.$set(_vm.product, "vat_percent", $$v)},expression:"product.vat_percent"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"label":_vm.$t('product-edit-hint-unit-type'),"items":_vm.measureUnits,"item-text":"name","item-value":"id","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.name))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.name))+" ")]}}],null,true),model:{value:(_vm.product.measure_unit),callback:function ($$v) {_vm.$set(_vm.product, "measure_unit", $$v)},expression:"product.measure_unit"}})]}}],null,true)})],1)],1),_c('div',{staticStyle:{"height":"10px"}}),(!_vm.sending_changes)?_c('v-btn',{attrs:{"width":"120","disabled":invalid,"color":"primary"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(_vm._s(_vm.$t("gbl-ok")))]):_vm._e(),(_vm.sending_changes)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"primary"}}):_vm._e(),_c('v-btn',{attrs:{"disabled":_vm.sending_changes,"width":"120","color":"secondary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(_vm._s(_vm.$t("gbl-cancel")))])],1):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }