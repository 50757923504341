<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="1280" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <AddressEditor
      ref="addressEditor"
      @addressConfigured="handleAddress"
      v-model="addressEditorDialog"
      :address_types="address_types"
    />
    <messageBox ref="msgBox" />
    <setCoordinates ref="setCoordinates" />

    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-account</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{
                    editingCustomer
                      ? $t("customer-editor-modify")
                      : $t("customer-editor-add")
                  }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <div class="vertical-spacer" />

            <v-progress-circular
              v-if="fetchingData"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="primary"
            ></v-progress-circular>

            <ValidationObserver
              v-if="!fetchingData"
              ref="obs"
              v-slot="{ invalid, validated }"
            >
              <v-card-text>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        outlined
                        dense
                        :label="$t('customer-editor-hint-type')"
                        v-model="customer.type"
                        :items="customer_types"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors"
                        :success="valid"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        outlined
                        dense
                        :label="$t('customer-editor-hint-category')"
                        v-model="customer.category_id"
                        :items="customer_categories"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors"
                        :success="valid"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row dense v-if="customer.type == 1 || customer.type == 3">
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        key="business-name"
                        v-model="customer.business_name"
                        :label="$t('customer-editor-hint-business-name')"
                        :error-messages="errors"
                        :success="valid"
                        outlined
                        dense
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense v-if="customer.type == 2">
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        key="first-name"
                        v-model="customer.first_name"
                        :label="$t('customer-editor-hint-first-name')"
                        :error-messages="errors"
                        :success="valid"
                        outlined
                        dense
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      v-if="customer.type == 2"
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        key="last-name"
                        v-model="customer.last_name"
                        :label="$t('customer-editor-hint-last-name')"
                        :error-messages="errors"
                        :success="valid"
                        outlined
                        dense
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      :rules="{ required: true, email: true }"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="customer.email"
                        :label="$t('customer-editor-hint-email')"
                        :error-messages="errors"
                        :success="valid"
                        outlined
                        dense
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="customer.secondary_email"
                      :label="$t('customer-editor-hint-secondary-email')"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        :label="$t('pricelist-editor-hint-master')"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors"
                        :success="valid"
                        outlined
                        dense
                        v-model="customer.price_list_id"
                        :items="pricelists"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      v-model="colorPickerColor"
                      readonly
                      hide-details
                      class="ma-0 pa-0"
                      outlined
                      dense
                      :label="$t('customer-editor-hint-color')"
                    >
                      <template v-slot:append>
                        <v-menu
                          v-model="menu"
                          top
                          nudge-bottom="105"
                          nudge-left="16"
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on }">
                            <div :style="swatchStyle" v-on="on" />
                          </template>
                          <v-card>
                            <v-card-text class="pa-0">
                              <v-color-picker v-model="colorPickerColor" flat />
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                      outlined
                      dense
                      hide-details
                      :label="$t('customer-editor-hint-closed-days')"
                      item-text="name"
                      item-value="id"
                      v-model="closed_days"
                      :items="days"
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      outlined
                      dense
                      hide-details
                      :label="$t('customer-editor-hint-payment-mode')"
                      item-text="name"
                      item-value="id"
                      v-model="customer.payment_mode"
                      :items="payment_modes"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="4">
                    <v-row class="ma-0"
                      ><v-checkbox
                        v-model="customer.enable_minimum_int_order_weight"
                        hide-details
                        class="shrink  ma-0 pt-2"
                      ></v-checkbox>
                      <ValidationProvider
                        style="width:90%"
                        :rules="
                          customer.enable_minimum_int_order_weight
                            ? 'required'
                            : ''
                        "
                        v-slot="{ errors, valid }"
                      >
                        <v-text-field
                          v-model="customer.minimum_int_order_weight"
                          :disabled="!customer.enable_minimum_int_order_weight"
                          hide-details
                          outlined
                          dense
                          :label="
                            $t('customer-editor-internal-order-min-weight')
                          "
                          :error-messages="errors"
                          :success="valid"
                        />
                      </ValidationProvider>
                    </v-row>
                  </v-col>
                  <v-col cols="4">
                    <v-row class="ma-0"
                      ><v-checkbox
                        v-model="customer.enable_minimum_int_order_num"
                        hide-details
                        class="shrink  ma-0 pt-2"
                      ></v-checkbox>
                      <ValidationProvider
                        style="width:90%"
                        :rules="
                          customer.enable_minimum_int_order_num
                            ? 'required'
                            : ''
                        "
                        v-slot="{ errors, valid }"
                      >
                        <v-text-field
                          v-model="customer.minimum_int_order_num"
                          :disabled="!customer.enable_minimum_int_order_num"
                          hide-details
                          outlined
                          dense
                          :label="$t('customer-editor-internal-order-min-num')"
                          :error-messages="errors"
                          :success="valid"
                        >
                        </v-text-field
                      ></ValidationProvider>
                    </v-row> </v-col
                  ><v-col cols="4">
                    <v-row class="ma-0"
                      ><v-checkbox
                        v-model="customer.enable_minimum_int_order_price"
                        hide-details
                        class="shrink  ma-0 pt-2"
                      ></v-checkbox>
                      <ValidationProvider
                        style="width:90%"
                        :rules="
                          customer.enable_minimum_int_order_price
                            ? 'required'
                            : ''
                        "
                        v-slot="{ errors, valid }"
                      >
                        <v-text-field
                          v-model="customer.minimum_int_order_price"
                          :disabled="!customer.enable_minimum_int_order_price"
                          hide-details
                          outlined
                          dense
                          :label="
                            $t('customer-editor-internal-order-min-price')
                          "
                          :error-messages="errors"
                          :success="valid"
                        >
                        </v-text-field
                      ></ValidationProvider>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="4">
                    <v-row class="ma-0"
                      ><v-checkbox
                        v-model="customer.enable_minimum_ext_order_weight"
                        hide-details
                        class="shrink  ma-0 pt-2"
                      ></v-checkbox>
                      <ValidationProvider
                        style="width:90%"
                        :rules="
                          customer.enable_minimum_ext_order_weight
                            ? 'required'
                            : ''
                        "
                        v-slot="{ errors, valid }"
                      >
                        <v-text-field
                          v-model="customer.minimum_ext_order_weight"
                          :disabled="!customer.enable_minimum_ext_order_weight"
                          hide-details
                          outlined
                          dense
                          :label="
                            $t('customer-editor-external-order-min-weight')
                          "
                          :error-messages="errors"
                          :success="valid"
                        >
                        </v-text-field
                      ></ValidationProvider>
                    </v-row>
                  </v-col>
                  <v-col cols="4">
                    <v-row class="ma-0"
                      ><v-checkbox
                        v-model="customer.enable_minimum_ext_order_num"
                        hide-details
                        class="shrink  ma-0 pt-2"
                      ></v-checkbox>
                      <ValidationProvider
                        style="width:90%"
                        :rules="
                          customer.enable_minimum_ext_order_num
                            ? 'required'
                            : ''
                        "
                        v-slot="{ errors, valid }"
                      >
                        <v-text-field
                          v-model="customer.minimum_ext_order_num"
                          :disabled="!customer.enable_minimum_ext_order_num"
                          hide-details
                          outlined
                          dense
                          :label="$t('customer-editor-external-order-min-num')"
                          :error-messages="errors"
                          :success="valid"
                        >
                        </v-text-field
                      ></ValidationProvider>
                    </v-row>
                  </v-col>
                  <v-col cols="4">
                    <v-row class="ma-0"
                      ><v-checkbox
                        v-model="customer.enable_minimum_ext_order_price"
                        hide-details
                        class="shrink  ma-0 pt-2"
                      ></v-checkbox>
                      <ValidationProvider
                        style="width:90%"
                        :rules="
                          customer.enable_minimum_ext_order_price
                            ? 'required'
                            : ''
                        "
                        v-slot="{ errors, valid }"
                      >
                        <v-text-field
                          v-model="customer.minimum_ext_order_price"
                          :disabled="!customer.enable_minimum_ext_order_price"
                          hide-details
                          outlined
                          dense
                          :label="
                            $t('customer-editor-external-order-min-price')
                          "
                          :error-messages="errors"
                          :success="valid"
                        >
                        </v-text-field
                      ></ValidationProvider>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="customer.enable_address_split_invoices"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="
                        $t('customer-editor-enable-address-split-invoices')
                      "
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="3">
                    <v-select
                      hide-details
                      outlined
                      dense
                      :label="$t('customer-editor-no-vat')"
                      v-model="customer.vat_exemption_id"
                      :items="vat_exemptions"
                      item-text="value"
                      item-value="id"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="customer.enable_maximum_days_unpayed"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="
                        $t('customer-editor-enable-invoices-max-days-unpayed')
                      "
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="customer.maximum_days_unpayed"
                      hide-details
                      class="ma-0 pa-0"
                      outlined
                      dense
                      :disabled="!customer.enable_maximum_days_unpayed"
                      :label="
                        $t('customer-editor-enable-invoices-max-days-unpayed')
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="customer.enable_maximum_unpayed_on_orders"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="$t('customer-editor-enable-orders-max-unpayed')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="customer.maximum_unpayed_on_orders"
                      hide-details
                      class="ma-0 pa-0"
                      outlined
                      dense
                      :disabled="!customer.enable_maximum_unpayed_on_orders"
                      :label="$t('customer-editor-enable-orders-max-unpayed')"
                    ></v-text-field>
                  </v-col>
                  <v-col col="3">
                    <v-checkbox
                      v-model="customer.enable_maximum_unpayed_on_invoices"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="$t('customer-editor-enable-invoices-max-unpayed')"
                    ></v-checkbox>
                  </v-col>
                  <v-col col="3">
                    <v-text-field
                      v-model="customer.maximum_unpayed_on_invoices"
                      hide-details
                      class="ma-0 pa-0"
                      outlined
                      dense
                      :disabled="!customer.enable_maximum_unpayed_on_invoices"
                      :label="$t('customer-editor-enable-invoices-max-unpayed')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="3">
                    <v-select
                      outlined
                      dense
                      :label="$t('customer-editor-doc-generation-mode')"
                      v-model="customer.doc_generation_mode"
                      :items="doc_generation_modes"
                      item-text="value"
                      item-value="id"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                      v-model="customer.show_invoice_ddt_addresses"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="$t('customer-editor-show-invoice-ddt-addresses')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="customer.show_invoice_shipment_address"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="
                        $t('customer-editor-show-invoice-shipment-addresses')
                      "
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="customer.send_invoice_by_mail"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="$t('customer-editor-send-invoice-by-mail')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="customer.send_ddt_by_mail"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="$t('customer-editor-send-ddt-by-mail')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="customer.hide_on_mobile_payments"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="$t('customer-hide-on-mobile-payments')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="customer.disable_cash_payments"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="$t('customer-disable-mobile-cache-payments')"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="customer.show_po_on_invoices"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="$t('customer-editor-show-po-on-invoices')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="customer.show_po_on_ddt"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="$t('customer-editor-show-po-on-ddt')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="customer.mandatory_po"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="$t('customer-editor-mandatory-po')"
                    ></v-checkbox> </v-col
                  ><v-col cols="3">
                    <v-checkbox
                      v-model="customer.external_order_all_products"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="$t('customer-editor-external-order-all-products')"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="3">
                    <v-checkbox
                      :disabled="customer.doc_generation_mode == 2"
                      v-model="customer.generate_edi_for_ddt"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="$t('customer-editor-generate-edi-for-ddt')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="customer.edi_unb"
                      hide-details
                      class="ma-0 pa-0"
                      outlined
                      dense
                      :disabled="
                        !customer.generate_edi_for_ddt &&
                          !customer.generate_edi_for_invoices
                      "
                      :label="$t('customer-editor-edi-unb')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="customer.edi_buyer_gln"
                      hide-details
                      class="ma-0 pa-0"
                      outlined
                      dense
                      :disabled="
                        !customer.generate_edi_for_ddt &&
                          !customer.generate_edi_for_invoices
                      "
                      :label="$t('customer-editor-edi-buyer-gln')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="customer.edi_invoice_gln"
                      hide-details
                      class="ma-0 pa-0"
                      outlined
                      dense
                      :disabled="
                        !customer.generate_edi_for_ddt &&
                          !customer.generate_edi_for_invoices
                      "
                      :label="$t('customer-editor-edi-invoice-gln')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <div style="height:10px" />
              <v-card>
                <v-toolbar height="40" color="info" dark>
                  <v-col cols="1"> </v-col
                  ><v-col cols="10">{{
                    $t("customer-editor-title-addresses")
                  }}</v-col>
                  <v-col class="text-right" cols="1">
                    <v-btn
                      v-if="$store.state.hasRight('158')"
                      icon
                      @click="addAddress()"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-toolbar>

                <v-container fluid>
                  <v-row v-if="fetchingAddresses">
                    <v-col col="12">
                      <v-progress-circular
                        :indeterminate="true"
                        :rotate="0"
                        :size="32"
                        :width="4"
                        color="primary"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                  <v-row v-if="!fetchingAddresses">
                    <v-col col="12">
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("address-tbl-type") }}
                            </th>
                            <th class="text-center">
                              {{ $t("address-tbl-nation") }}
                            </th>
                            <th class="text-center">
                              {{ $t("address-tbl-address") }}
                            </th>
                            <th class="text-center">
                              {{ $t("address-tbl-city") }}
                            </th>
                            <th class="text-center">
                              {{ $t("address-tbl-pc") }}
                            </th>
                            <th class="text-center"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in customer.addresses"
                            :key="index"
                          >
                            <td class="text-center">
                              {{ $t(item.type_name) }}
                            </td>
                            <td class="text-center">{{ item.nation }}</td>
                            <td class="text-center">{{ item.address }}</td>
                            <td class="text-center">
                              {{ item.city ? item.city : item.cityname }}
                            </td>
                            <td class="text-center">{{ item.postal_code }}</td>
                            <td class="text-center">
                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="secondary"
                                    @click.stop="setCoords(item)"
                                    v-on="on"
                                    ><v-icon>mdi-target</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("address-action-gmap-set-latlong")
                                }}</span></v-tooltip
                              >

                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    :disabled="!item.lat || !item.long"
                                    x-small
                                    text
                                    icon
                                    color="orange"
                                    @click.stop="locateCoordAddress(item)"
                                    v-on="on"
                                    ><v-icon>mdi-google-maps</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("address-action-gmap-latlong")
                                }}</span></v-tooltip
                              >

                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="primary"
                                    @click.stop="locateAddress(item)"
                                    v-on="on"
                                    ><v-icon>mdi-google-maps</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("address-action-gmap")
                                }}</span></v-tooltip
                              >

                              <v-tooltip
                                v-if="$store.state.hasRight('159')"
                                bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="gray"
                                    @click.stop="editAddress(item)"
                                    v-on="on"
                                    ><v-icon>mdi-pencil</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("address-action-edit")
                                }}</span></v-tooltip
                              >

                              <v-tooltip
                                v-if="$store.state.hasRight('160')"
                                bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="error"
                                    @click.stop="deleteAddress(item.id)"
                                    v-on="on"
                                    ><v-icon
                                      >mdi-delete-circle-outline</v-icon
                                    ></v-btn
                                  ></template
                                ><span>{{
                                  $t("address-action-delete")
                                }}</span></v-tooltip
                              >
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>

              <div style="height:10px" />

              <v-row dense>
                <v-col>
                  <span class="red--text" v-if="!has_invoice_address()">{{
                    $t("customer-editor-fiscal-address-mandatory")
                  }}</span>
                </v-col>
              </v-row>

              <v-btn
                width="120"
                :key="ok_counter"
                :disabled="
                  invalid || fetchingAddresses || !has_invoice_address()
                "
                v-on:click="confirm()"
                color="primary"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn
                :disabled="fetchingAddresses"
                width="120"
                v-on:click="cancel()"
                color="secondary"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<style scoped>
.card-title-text {
  font-size: 1.5em;
}
</style>

<script>
import AddressEditor from "./AddressEditor";
import messageBox from "../components/MessageBox";
import setCoordinates from "../components/SetCoordinates";
import staticData from "../apis/static";
import priceListsManager from "../apis/pricelists";
import customersManager from "../apis/customers";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      customer: {
        addresses: [],
        reference_color: "#885b31ff",
        closed_days: ""
      },
      vat_exemptions: [],
      ok_counter: 0,
      customer_types: [],
      customer_categories: [],
      address_types: [],
      pricelists: [],
      addressEditorDialog: false,
      editingCustomer: false,
      fetchingAddresses: false,
      fetchingData: true,
      colorPickerColor: "#885b31ff",
      days: staticData.days,
      payment_modes: staticData.payment_modes,
      menu: false,
      closed_days: []
    };
  },

  computed: {
    swatchStyle() {
      const { colorPickerColor, menu } = this;
      return {
        backgroundColor: colorPickerColor,
        cursor: "pointer",
        height: "25px",
        width: "25px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    AddressEditor,
    messageBox,
    setCoordinates
  },

  props: ["value"],

  mounted() {
    this.doc_generation_modes = [
      {
        id: 1,
        value: this.$t("customer-editor-doc-generation-mode-ddt")
      },
      {
        id: 3,
        value: this.$t("customer-editor-doc-generation-mode-ddt-values")
      },
      { id: 2, value: this.$t("customer-editor-doc-generation-mode-invoice") }
    ];
  },

  methods: {
    has_invoice_address: function() {
      for (let n = 0; n < this.customer.addresses.length; n++) {
        let address = this.customer.addresses[n];
        if (address.address_type == 2) return true;
      }
      return false;
    },

    fetchAddresses: function(customer_id, unique) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchAddresses(customer_id, unique)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    addAddress: function() {
      this.$refs.addressEditor.setItem(false, null, this.customer.type);
      this.addressEditorDialog = true;
    },

    locateAddress: function(address) {
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" +
          encodeURI(
            address.address +
              " " +
              (address.city ? address.city : address.cityname)
          )
      );
    },

    setCoords: function(address) {
      this.$refs.setCoordinates
        .show(address.lat, address.long)
        .then(res => {
          this.$store.state.global_overlay = true;
          this.$store.state.global_overlay_text = this.$t(
            "set-coordinates-in-progress"
          );

          customersManager
            .updateAddressCoordinates(address.id, res)
            .then(() => {
              this.$store.state.global_overlay = false;
              address.lat = res.latitude;
              address.long = res.longitude;
            })
            .catch(err => {
              console.log(err);
              this.$store.state.global_overlay = false;
            });
        })
        .catch(() => {});
    },

    locateCoordAddress: function(address) {
      window.open(
        "https://maps.google.com?q=" + address.lat + "," + address.long
      );
    },

    editAddress: function(address) {
      this.$refs.addressEditor.setItem(true, address, this.customer.type);
      this.addressEditorDialog = true;
    },

    deleteAddress: function(id) {
      this.$refs.msgBox
        .show(
          this.$t("address-delete-confirm"),
          this.$t("address-delete-title")
        )
        .then(() => {
          this.fetchingAddresses = true;
          customersManager
            .removeAddress(id)
            .then(() => {
              this.fetchAddresses(this.customer.id, true).then(res => {
                this.customer.addresses = [
                  ...res.invoice_addresses,
                  ...res.shipment_addresses
                ];
                this.fetchingAddresses = false;
                this.ok_counter++;
              });
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    handleAddress: function(context) {
      if (context.editingAddress) {
        if (this.customer.id) {
          customersManager
            .updateAddress(context.address)
            .then(() => {
              this.fetchingAddresses = true;
              this.fetchAddresses(this.customer.id, true).then(res => {
                this.customer.addresses = [
                  ...res.invoice_addresses,
                  ...res.shipment_addresses
                ];
                this.fetchingAddresses = false;
                this.addressEditorDialog = false;
                this.ok_counter++;
              });
            })
            .catch(err => {
              console.log(err);
              this.addressEditorDialog = false;
            });
        } else {
          this.fetchingAddresses = false;
          this.addressEditorDialog = false;
          this.ok_counter++;
        }
      } else {
        if (!this.customer.id) {
          this.customer.addresses.push(context.address);
        } else {
          context.address.customer_id = this.customer.id;

          customersManager
            .addAddress(context.address)
            .then(() => {
              this.fetchingAddresses = true;
              this.fetchAddresses(this.customer.id, true).then(res => {
                this.customer.addresses = [
                  ...res.invoice_addresses,
                  ...res.shipment_addresses
                ];
                this.fetchingAddresses = false;
                this.ok_counter++;
              });
            })
            .catch(err => {
              console.log(err);
            });
        }

        this.addressEditorDialog = false;
      }
    },

    confirm: function() {
      this.customer.closed_days = this.closed_days.join(",");
      this.customer.reference_color = this.colorPickerColor;

      this.$emit("customerConfigured", {
        editingCustomer: this.editingCustomer,
        customer: this.customer
      });
      this.$refs.obs.reset();
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    resetModal() {
      this.customer.type = 1;
      this.customer.business_name = "";
      this.customer.first_name = "";
      this.customer.last_name = "";
      this.customer.addresses = [];
      this.closed_days = [];
      this.payment_mode = 1;
      this.colorPickerColor = "#885b31ff";
      this.customer.enable_maximum_unpayed_on_orders = false;
      this.customer.enable_maximum_unpayed_on_invoices = false;
      this.customer.show_invoice_ddt_addresses = false;
      this.customer.show_invoice_shipment_address = false;
      this.customer.send_invoice_by_mail = true;
      this.customer.show_po_on_invoices = false;
      this.customer.show_po_on_ddt = false;
      this.customer.send_ddt_by_mail = false;
      this.customer.hide_on_mobile_payments = false;
      this.customer.disable_cash_payments = false;
      this.customer.enable_maximum_days_unpayed = false;
      this.customer.enable_address_split_invoices = false;
      this.customer.generate_edi_for_invoices = false;
      this.customer.generate_edi_for_ddt = false;
      this.customer.edi_buyer_gln = null;
      this.customer.edi_invoice_gln = null;
      this.customer.edi_unb = null;
      this.customer.doc_generation_mode = 1;
      this.customer.vat_exemption_id = 0;
      this.customer.enable_minimum_ext_order_num = false;
      this.customer.enable_minimum_ext_order_weight = false;
      this.customer.enable_minimum_ext_order_price = false;
      this.customer.enable_minimum_int_order_num = false;
      this.customer.enable_minimum_int_order_weight = false;
      this.customer.enable_minimum_int_order_price = false;
      this.customer.minimum_ext_order_num = 0;
      this.customer.minimum_ext_order_weight = 0;
      this.customer.minimum_ext_order_price = 0;
      this.customer.minimum_int_order_num = 0;
      this.customer.minimum_int_order_weight = 0;
      this.customer.minimum_int_order_price = 0;
    },

    setItem(editing, customer) {
      this.resetModal();
      this.fetchingData = true;
      customersManager
        .fetchTypes()
        .then(res => {
          this.customer_types = res.types.map(x => {
            x.name = this.$t(x.type);
            return x;
          });
          customersManager
            .fetchCategories()
            .then(res => {
              this.customer_categories = res.items;
              customersManager
                .fetchAddressType()
                .then(res => {
                  this.address_types = res.types.map(x => {
                    x.locale_string = x.name;
                    x.name = this.$t(x.name);
                    return x;
                  });
                  customersManager
                    .fetchVatExemptions()
                    .then(res => {
                      this.vat_exemptions = [
                        { id: 0, value: this.$t("gbl-none-female") },
                        ...res.exemptions
                      ];
                      if (!customer.reference_color)
                        customer.reference_color = "#885b31ff";
                      this.colorPickerColor = customer.reference_color;
                      this.fetchingData = true;
                      priceListsManager
                        .fetchPriceListsNames()
                        .then(res => {
                          this.pricelists = [
                            { id: 0, name: this.$t("gbl-none-male") },
                            ...res
                          ];
                          this.editingCustomer = editing;
                          this.fetchingAddresses = true;

                          if (editing) {
                            this.customer = Object.assign({}, customer);
                            this.$set(
                              this.customer,
                              "enable_minimum_ext_order_num",
                              false
                            );
                            this.$set(
                              this.customer,
                              "enable_minimum_ext_order_weight",
                              false
                            );
                            this.$set(
                              this.customer,
                              "enable_minimum_ext_order_price",
                              false
                            );
                            this.$set(
                              this.customer,
                              "enable_minimum_int_order_num",
                              false
                            );
                            this.$set(
                              this.customer,
                              "enable_minimum_int_order_weight",
                              false
                            );
                            this.$set(
                              this.customer,
                              "enable_minimum_int_order_price",
                              false
                            );
                            if (this.customer.minimum_ext_order_num)
                              this.customer.enable_minimum_ext_order_num = true;
                            else
                              this.customer.enable_minimum_ext_order_num = false;
                            if (this.customer.minimum_ext_order_weight)
                              this.customer.enable_minimum_ext_order_weight = true;
                            else
                              this.customer.enable_minimum_ext_order_weight = false;

                            if (this.customer.minimum_ext_order_price)
                              this.customer.enable_minimum_ext_order_price = true;
                            else
                              this.customer.enable_minimum_ext_order_price = false;

                            if (this.customer.minimum_int_order_num)
                              this.customer.enable_minimum_int_order_num = true;
                            else
                              this.customer.enable_minimum_int_order_num = false;

                            if (this.customer.minimum_int_order_weight)
                              this.customer.enable_minimum_int_order_weight = true;
                            else
                              this.customer.enable_minimum_int_order_weight = false;

                            if (this.customer.minimum_int_order_price)
                              this.customer.enable_minimum_int_order_price = true;
                            else
                              this.customer.enable_minimum_int_order_price = false;

                            if (customer.closed_days) {
                              this.closed_days = customer.closed_days
                                .split(",")
                                .map(x => {
                                  return parseInt(x);
                                });
                            } else {
                              this.closed_days = [];
                            }
                            this.fetchAddresses(customer.id, true)
                              .then(res => {
                                this.customer.addresses = [
                                  ...res.invoice_addresses,
                                  ...res.shipment_addresses
                                ];
                                this.fetchingAddresses = false;
                                this.fetchingData = false;
                              })
                              .catch(err => {
                                this.fetchingAddresses = false;
                                this.fetchingData = false;
                                console.log(err);
                              });
                            requestAnimationFrame(() => {
                              if (this.$refs.obs) {
                                this.$refs.obs.validate();
                              }
                            });
                          } else {
                            this.fetchingData = false;
                            this.fetchingAddresses = false;
                            this.customer = {
                              id: null,
                              type: 1,
                              business_name: "",
                              first_name: "",
                              last_name: "",
                              addresses: [],
                              price_list_id: 0,
                              reference_color: "#885b31ff",
                              closed_days: "",
                              payment_mode: 2,
                              enable_minimum_ext_order_num: false,
                              enable_minimum_ext_order_weight: false,
                              enable_minimum_ext_order_price: false,
                              enable_minimum_int_order_num: false,
                              enable_minimum_int_order_weight: false,
                              enable_minimum_int_order_price: false,
                              minimum_ext_order_num: 0,
                              minimum_ext_order_weight: 0,
                              minimum_ext_order_price: 0,
                              minimum_int_order_num: 0,
                              minimum_int_order_weight: 0,
                              minimum_int_order_price: 0,
                              enable_maximum_unpayed_on_orders: false,
                              enable_maximum_unpayed_on_invoices: false,
                              enable_maximum_days_unpayed: false,
                              show_invoice_ddt_addresses: false,
                              show_invoice_shipment_address: false,
                              send_invoice_by_mail: true,
                              show_po_on_invoices: false,
                              show_po_on_ddt: false,
                              mandatory_po: false,
                              external_order_all_products: 0,
                              send_ddt_by_mail: false,
                              hide_on_mobile_payments: false,
                              disable_cash_payments: false,
                              enable_address_split_invoices: false,
                              generate_edi_for_invoices: false,
                              generate_edi_for_ddt: false,
                              edi_buyer_gln: null,
                              edi_invoice_gln: null,
                              edi_unb: null,
                              doc_generation_mode: 1,
                              maximum_unpayed_on_orders: 0,
                              maximum_unpayed_on_invoices: 0,
                              maximum_days_unpayed: 0,
                              vat_exemption_id: 0,
                              category_id: 1
                            };
                            this.closed_days = [];
                            requestAnimationFrame(() => {
                              if (this.$refs.obs) this.$refs.obs.reset();
                            });
                          }
                        })
                        .catch(err => {
                          this.fetchingData = false;
                          console.log(err);
                        });
                    })
                    .catch(err => {
                      console.log(err);
                      this.fetchingData = false;
                    });
                })
                .catch(err => {
                  console.log(err);
                  this.fetchingData = false;
                });
            })
            .catch(err => {
              console.log(err);
              this.fetchingData = false;
            });
        })
        .catch(err => {
          console.log(err);
          this.fetchingData = false;
        });
    }
  }
};
</script>
