var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"justify-center":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[(_vm.loadingData)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"primary"}}):_vm._e(),(!_vm.loadingData)?_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","icon":"mdi-package-variant-closed","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-1 font-weight-light card-header"},[_vm._v(" "+_vm._s(_vm.$t("allergenscard-title"))+" ")])]},proxy:true}],null,false,2169108119)},[_c('div',{staticStyle:{"height":"10px"}}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{staticClass:"mr-auto pl-1",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('gbl-search'),"hide-details":"","single-line":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('div',{staticStyle:{"height":"10px"}}),_c('v-select',{attrs:{"label":_vm.$t('productsmng-category'),"hide-details":"","item-text":"name","item-value":"id","outlined":"","dense":"","items":_vm.categories},model:{value:(_vm.filterByProductCategory),callback:function ($$v) {_vm.filterByProductCategory=$$v},expression:"filterByProductCategory"}})],1)],1),_c('div',{staticClass:"vertical-spacer"}),_c('v-data-table',{staticClass:"grid-table",attrs:{"items-per-page":_vm.perPage,"must-sort":true,"headers":_vm.headers,"items":_vm.products,"options":_vm.options,"server-items-length":_vm.totalProducts,"loading":_vm.loadingProducts,"footer-props":_vm.dataTableFooterOptions},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.pimage",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"height":"64px","width":"64px","margin":"5px"}},[(!item.image)?_c('v-img',{key:item.fetchKey,attrs:{"max-width":"64","max-height":"64","src":require('../assets/product.png')}}):_vm._e(),(item.image)?_c('v-img',{key:item.fetchKey,attrs:{"max-width":"64","max-height":"64","src":item.fetchedImage}}):_vm._e(),(item.fetchingImage)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":64,"width":6,"color":"primary"}}):_vm._e()],1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.price))+" ")]}},{key:"item.allergen_card",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.allergen_card ? _vm.stripePath(item.allergen_card) : _vm.$t("gbl-none-female"))+" "),(item.allergen_card)?_c('v-btn',{staticClass:"ma-0 pa-0",attrs:{"text":"","icon":"","color":"green"},on:{"click":function($event){return _vm.downloadAllergenCard(item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}},{key:"item.measure_unit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.measureUnitById[item.measure_unit]))+" ")]}}],null,false,3800079782)})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }