var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":"0.6","persistent":"","max-width":"600","value":_vm.value}},[_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-office-building-marker-outline")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"25px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.editingContact ? _vm.$t("contact-editor-title-modify") : _vm.$t("contact-editor-title-add")))]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[(!_vm.sending_changes)?_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")]):_vm._e()],1)],1)]},proxy:true}])},[_c('div',{staticClass:"vertical-spacer"}),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [(_vm.loadingTypes)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"primary"}}):_vm._e(),(!_vm.loadingTypes)?_c('div',[(!_vm.loadingTypes)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"label":_vm.$t('contact-editor-type'),"item-text":"name","item-value":"id","outlined":"","dense":"","disabled":_vm.loadingTypes,"items":_vm.contact_types,"return-object":""},model:{value:(_vm.contact_type),callback:function ($$v) {_vm.contact_type=$$v},expression:"contact_type"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":_vm.editingContact,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":_vm.$t('contact-editor-name'),"outlined":"","dense":"","error-messages":errors,"success":valid},model:{value:(_vm.contact.name),callback:function ($$v) {_vm.$set(_vm.contact, "name", $$v)},expression:"contact.name"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('contact-editor-phone'),"outlined":"","dense":""},model:{value:(_vm.contact.phone),callback:function ($$v) {_vm.$set(_vm.contact, "phone", $$v)},expression:"contact.phone"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('contact-editor-mobile'),"outlined":"","dense":""},model:{value:(_vm.contact.mobile),callback:function ($$v) {_vm.$set(_vm.contact, "mobile", $$v)},expression:"contact.mobile"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('contact-editor-email'),"outlined":"","dense":""},model:{value:(_vm.contact.email),callback:function ($$v) {_vm.$set(_vm.contact, "email", $$v)},expression:"contact.email"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('contact-editor-secondary-email'),"outlined":"","dense":""},model:{value:(_vm.contact.secondary_email),callback:function ($$v) {_vm.$set(_vm.contact, "secondary_email", $$v)},expression:"contact.secondary_email"}})],1)],1),_c('div',{staticStyle:{"height":"10px"}}),(!_vm.sending_changes)?_c('v-btn',{attrs:{"width":"120","disabled":invalid,"color":"primary"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(_vm._s(_vm.$t("gbl-ok")))]):_vm._e(),(_vm.sending_changes)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"primary"}}):_vm._e(),_c('v-btn',{attrs:{"disabled":_vm.sending_changes,"width":"120","color":"secondary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(_vm._s(_vm.$t("gbl-cancel")))])],1):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }