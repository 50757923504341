<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <v-dialog
          overlay-opacity="0.6"
          max-width="600"
          :value="changeOrderStateDialog"
          persistent
        >
          <v-container fill-height fluid grid-list-xl>
            <v-layout justify-center wrap>
              <v-flex md12>
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <v-row class="pa-0">
                      <v-col cols="1" class="pa-0"
                        ><v-icon large
                          >mdi-office-building-marker-outline</v-icon
                        ></v-col
                      >
                      <v-col
                        align="center"
                        cols="10"
                        class="pa-0 card-title-text"
                        style="font-size: 25px"
                        >{{ $t("order-state-editor-title-modify") }}</v-col
                      >
                      <v-col cols="1" class="pa-0"
                        ><v-icon @click="changeOrderStateDialog = false" large
                          >mdi-close</v-icon
                        ></v-col
                      >
                    </v-row>
                  </template>
                  <div style="height:10px" />

                  <v-select
                    :label="$t('order-state-editor-hint-type')"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    v-model="orderStateDialogValue"
                    :items="orderStates"
                  />
                  <v-btn @click="handleChangeOrderState()" color="primary">{{
                    $t("profile-change-pwd-confirm")
                  }}</v-btn>
                </base-material-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-dialog>

        <v-dialog
          overlay-opacity="0.6"
          max-width="600"
          :value="changeOrderPaymentStateDialog"
          persistent
        >
          <v-container fill-height fluid grid-list-xl>
            <v-layout justify-center wrap>
              <v-flex md12>
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <v-row class="pa-0">
                      <v-col cols="1" class="pa-0"
                        ><v-icon large
                          >mdi-office-building-marker-outline</v-icon
                        ></v-col
                      >
                      <v-col
                        align="center"
                        cols="10"
                        class="pa-0 card-title-text"
                        style="font-size: 25px"
                        >{{ $t("payment-editor-title-modify") }}</v-col
                      >
                      <v-col cols="1" class="pa-0"
                        ><v-icon
                          @click="changeOrderPaymentStateDialog = false"
                          large
                          >mdi-close</v-icon
                        ></v-col
                      >
                    </v-row>
                  </template>
                  <div style="height:10px" />
                  <v-select
                    :label="$t('payment-editor-hint-type')"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    v-model="orderPaymentStateDialogValue"
                    :items="getPaymentStates"
                  />
                  <v-btn
                    @click="handleChangePaymentOrderState()"
                    color="primary"
                    >{{ $t("profile-change-pwd-confirm") }}</v-btn
                  >
                </base-material-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-dialog>

        <messageBox ref="msgBox" />

        <v-row>
          <v-col cols="12" sm="6" lg="6">
            <base-material-stats-card
              color="success"
              icon="mdi-currency-eur"
              :title="$t('invoicesreports-total-payed')"
              :value="computeTotalPayed"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="6">
            <base-material-stats-card
              color="warning"
              icon="mdi-currency-eur"
              :title="$t('invoicesreports-total-not-payed')"
              :value="computeTotalUnpayed"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
        </v-row>

        <div class="vertical-spacer" />
        <FiltersBar
          :filters="filters"
          :disabled="loadingOrders"
          @onFiltersLoading="onFiltersLoading"
          @onFiltersLoaded="onFiltersLoaded"
          :showLoader="true"
          :OrdersFilter="true"
          :CourierFilter="true"
        />

        <v-btn
          v-if="$store.state.hasRight('203')"
          @click="downloadOrdersShipment()"
          color="primary"
          >{{ $t("ordermng-download-orders") }}</v-btn
        >

        <base-material-card
          v-if="!loadingFilters"
          color="primary"
          icon="mdi-cube-send"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("ordermng-title") }}
            </div>
          </template>

          <div style="height:10px" />
          <v-text-field
            v-model="filter"
            append-icon="mdi-magnify"
            class="mr-auto pl-1"
            :label="$t('gbl-search')"
            hide-details
            single-line
            style="max-width: 250px;"
          />

          <div class="vertical-spacer" />

          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="orders"
            :options.sync="options"
            :server-items-length="totalOrders"
            :loading="loadingOrders"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.creation_date="{ item }">
              {{ item.creation_date | toLocaleDate }}
            </template>

            <template v-slot:item.shipment_date="{ item }">
              {{ item.shipment_date | toLocaleDate }}
            </template>

            <template v-slot:item.total_gross="{ item }">
              {{ item.total_gross.toFixed(2) | toCurrency }} €
            </template>

            <template v-slot:item.business_name="{ item }">
              <v-icon :color="item.reference_color">mdi-circle</v-icon
              >{{
                item.customer
                  ? item.customer
                  : item.first_name + " " + item.last_name
              }}
            </template>

            <template v-slot:item.ddt_number="{ item }">
              <div style="min-width:140px">
                {{
                  item.ddt_number ? item.ddt_number + " - " + item.ddt_year : ""
                }}

                <v-tooltip
                  v-if="
                    $store.state.hasRight('186') &&
                      item.ddt_generation_id &&
                      !$store.state.spooler_online
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="cyan"
                      @click="printDDT(item)"
                      v-on="on"
                      ><v-icon>mdi-printer</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-print-ddt") }}</span></v-tooltip
                >

                <v-tooltip
                  v-if="
                    $store.state.hasRight('186') &&
                      item.ddt_generation_id &&
                      $store.state.spooler_online
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="cyan"
                      @click="printDDT(item, true)"
                      v-on="on"
                      ><v-icon>mdi-printer-wireless</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-print-ddt") }}</span></v-tooltip
                >

                <v-tooltip
                  v-if="
                    $store.state.hasRight('187') &&
                      item.ddt_generation_id &&
                      !$store.state.spooler_online
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click="printDDTWithAmounts(item)"
                      v-on="on"
                      ><v-icon>mdi-printer</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("ordermng-print-ddt-with-amounts")
                  }}</span></v-tooltip
                >

                <v-tooltip
                  v-if="
                    $store.state.hasRight('187') &&
                      item.ddt_generation_id &&
                      $store.state.spooler_online
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click="printDDTWithAmounts(item, true)"
                      v-on="on"
                      ><v-icon>mdi-printer-wireless</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("ordermng-print-ddt-with-amounts")
                  }}</span></v-tooltip
                >

                <v-tooltip
                  v-if="$store.state.hasRight('186') && item.ddt_generation_id"
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="cyan"
                      @click="downloadDDT(item)"
                      v-on="on"
                      ><v-icon>mdi-file-table-outline</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-download-ddt") }}</span></v-tooltip
                >

                <v-tooltip
                  v-if="$store.state.hasRight('187') && item.ddt_generation_id"
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click="downloadDDTWithAmounts(item)"
                      v-on="on"
                      ><v-icon>mdi-file-document-outline</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("ordermng-download-ddt-with-amounts")
                  }}</span></v-tooltip
                >
              </div>
            </template>

            <template v-slot:item.invoice_number="{ item }">
              <div style="min-width:100px">
                {{
                  item.invoice_number
                    ? item.invoice_number + " - " + item.invoice_year
                    : ""
                }}

                <v-tooltip
                  v-if="
                    $store.state.hasRight('187') &&
                      item.invoice_generation_id &&
                      !$store.state.spooler_online
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click="printInvoice(item)"
                      v-on="on"
                      ><v-icon>mdi-printer</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-print-invoice") }}</span></v-tooltip
                >

                <v-tooltip
                  v-if="
                    $store.state.hasRight('187') &&
                      item.invoice_generation_id &&
                      $store.state.spooler_online
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click="printInvoice(item, true)"
                      v-on="on"
                      ><v-icon>mdi-printer-wireless</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-print-invoice") }}</span></v-tooltip
                >

                <v-tooltip
                  v-if="
                    $store.state.hasRight('187') && item.invoice_generation_id
                  "
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click="downloadInvoice(item)"
                      v-on="on"
                      ><v-icon>mdi-file-check-outline</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-download-invoice") }}</span></v-tooltip
                >
              </div>
            </template>

            <template v-slot:item.state="{ item }">
              {{ getOrderState(item.state) }}
              <v-tooltip v-if="$store.state.hasRight('191')" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="orange darken-2"
                    @click="setOrderState(item)"
                    v-on="on"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  ></template
                ><span>{{ $t("ordermng-change-state") }}</span></v-tooltip
              >
            </template>

            <template v-slot:item.payment_state="{ item }">
              {{ getOrderPaymentState(item.payment_state) }}
              <v-tooltip v-if="$store.state.hasRight('190')" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="orange darken-2"
                    @click="setOrderPaymentState(item)"
                    v-on="on"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  ></template
                ><span>{{ $t("ordermng-change-state") }}</span></v-tooltip
              >
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import ordersManager from "../apis/orders";
import usersManager from "../apis/users";

import FiltersBar from "../components/FiltersBar";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      changeOrderStateDialog: false,
      orderStateDialogItem: null,
      orderStateDialogValue: null,

      changeOrderPaymentStateDialog: false,
      orderPaymentStateDialogItem: null,
      orderPaymentStateDialogValue: null,

      orderStates: staticData.ordersStates,
      paymentStates: staticData.ordersPaymentStates,

      options: {
        sortBy: ["id"],
        sortDesc: [true]
      },

      headers: [],
      perPage: 100,
      totalOrders: 0,
      filter: "",

      filters: {
        filterByCourierId: this.$store.state.user
          ? this.$store.state.user.id
          : 0,
        filterByCustomerId: null,
        filterByPeriod: 12,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByOrderState: 1,
        filterBySalesPoint: null
      },
      orders: [],
      loadingOrders: false,
      loadingFilters: true,

      stats: null,
      payed: null,
      unpayed: null
    };
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("ordersmng-tbl-id"),
        align: "left",
        sortable: true,
        value: "id"
      },
      {
        text: this.$t("ordersmng-tbl-customer"),
        align: "left",
        sortable: true,
        value: "business_name"
      },
      {
        text: this.$t("ordersmng-tbl-date"),
        align: "left",
        sortable: true,
        value: "creation_date"
      },
      {
        text: this.$t("ordersmng-tbl-shipment-date"),
        align: "left",
        sortable: true,
        value: "shipment_date"
      },

      {
        text: this.$t("ordersmng-tbl-state"),
        align: "left",
        sortable: true,
        value: "state"
      },
      {
        text: this.$t("ordersmng-tbl-payment-state"),
        align: "left",
        sortable: true,
        value: "payment_state"
      },
      {
        text: this.$t("ordersmng-tbl-gross"),
        align: "left",
        sortable: true,
        value: "total_gross"
      },
      {
        text: this.$t("ordersmng-tbl-amount"),
        align: "left",
        sortable: true,
        value: "amount"
      },
      {
        text: this.$t("ordersmng-tbl-ddt"),
        align: "left",
        sortable: true,
        value: "ddt_number"
      },
      {
        text: this.$t("ordersmng-tbl-invoice"),
        align: "left",
        sortable: true,
        value: "invoice_number"
      },

      {
        text: this.$t("ordersmng-tbl-notes"),
        align: "left",
        sortable: true,
        value: "notes"
      },

      {
        text: this.$t("ordersmng-tbl-private-notes"),
        align: "left",
        sortable: true,
        value: "private_notes"
      }
    ];
  },

  components: {
    messageBox,
    FiltersBar
  },

  computed: {
    getPaymentStates() {
      let ar = [...this.paymentStates].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      return ar;
    },
    shipment_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(this.shipment_date_value);
      }
    },

    filterByPeriodText() {
      return staticData.statisticsPeriods[this.filters.filterByPeriod].name;
    },

    computeGrossValue() {
      if (this.stats)
        return this.$options.filters.toCurrencySymbol(
          this.stats.net_total + this.stats.vat_total
        );
      else return "...";
    },

    computeNetValue() {
      if (this.stats) {
        return this.$options.filters.toCurrencySymbol(this.stats.net_total);
      } else return "...";
    },

    computeTotalPayed() {
      if (this.payed) {
        return this.$options.filters.toCurrencySymbol(this.payed.net_total);
      } else return "...";
    },

    computeTotalUnpayed() {
      if (this.unpayed) {
        return this.$options.filters.toCurrencySymbol(this.unpayed.net_total);
      } else return "...";
    }
  },

  watch: {
    options: {
      handler() {
        this.fetchCourierOrders();
      },
      deep: true
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchCourierOrders();
        }, 300);
      }
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchCourierOrders();
        }, 500);
      },
      deep: true
    }
  },

  methods: {
    downloadOrdersShipment() {
      let ctx = {};
      ctx.filterByCourierId = this.$store.state.user.id;
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByOrderState = this.filters.filterByOrderState;

      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadOrdersShipment(ctx, this.$store.state.user.locale)
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    onFiltersLoading() {
      this.loadingFilters = true;
    },

    onFiltersLoaded() {
      this.loadingFilters = false;
    },

    getOrderState(state) {
      return staticData.ordersStates[state].name;
    },

    getOrderPaymentState(state) {
      return staticData.ordersPaymentStates[state].name;
    },

    fetchCourierOrders() {
      this.loadingOrders = true;
      this.totalOrders = 0;
      this.orders = [];
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.filterByCourierId = this.$store.state.user.id;
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByOrderState = this.filters.filterByOrderState;
      ctx.filterBySalesPoint = this.filters.filterBySalesPoint;

      ordersManager
        .fetchOrders(ctx)
        .then(result => {
          this.stats = result.stats;
          this.payed = result.payed;
          this.unpayed = result.unpayed;
          if (!this.payed.net_total) this.payed.net_total = 0.0;
          if (!this.unpayed.net_total) this.unpayed.net_total = 0.0;

          if (!this.stats.net_total) this.stats.net_total = 0.0;
          if (!this.stats.vat_total) this.stats.vat_total = 0.0;
          this.totalOrders = result.totalCount;
          this.orders = result.items;

          this.loadingOrders = false;
        })
        .catch(err => {
          this.loadingOrders = false;
          console.log(err);
        });
    },

    setOrderState(item) {
      this.changeOrderStateDialog = true;
      this.orderStateDialogValue = item.state;
      this.orderStateDialogItem = item;
    },

    setOrderPaymentState(item) {
      this.changeOrderPaymentStateDialog = true;
      this.orderPaymentStateDialogValue = item.payment_state;
      this.orderPaymentStateDialogItem = item;
    },

    handleChangeOrderState() {
      ordersManager
        .changeStatus(this.orderStateDialogItem.id, this.orderStateDialogValue)
        .then(() => {
          this.orderStateDialogItem.state = this.orderStateDialogValue;
          this.changeOrderStateDialog = false;
          this.orderStateDialogItem = null;
          this.orderStateDialogValue = null;
        })
        .catch(err => {
          console.log(err);
          this.changeOrderStateDialog = false;
          this.orderStateDialogItem = null;
          this.orderStateDialogValue = null;
        });
    },

    handleChangePaymentOrderState() {
      ordersManager
        .changePaymentStatus(
          this.orderPaymentStateDialogItem.id,
          this.orderPaymentStateDialogValue
        )
        .then(() => {
          this.orderPaymentStateDialogItem.payment_state = this.orderPaymentStateDialogValue;
          this.changeOrderPaymentStateDialog = false;
          this.orderPaymentStateDialogItem = null;
          this.orderPaymentStateDialogValue = null;
          this.fetchCourierOrders();
        })
        .catch(err => {
          console.log(err);
          this.changeOrderPaymentStateDialog = false;
          this.orderPaymentStateDialogItem = null;
          this.orderPaymentStateDialogValue = null;
        });
    },

    printInvoice(item, spooler) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .printInvoice(
              item.invoice_number,
              item.invoice_year,
              this.$store.state.user.locale,
              spooler
            )
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    printDDTWithAmounts(item, spooler) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .printDDTWithAmounts(
              item.id,
              this.$store.state.user.locale,
              spooler
            )
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    printDDT(item, spooler) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .printDDT(item.id, this.$store.state.user.locale, spooler)
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadInvoice(item) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadInvoice(
              item.invoice_number,
              item.invoice_year,
              this.$store.state.user.locale
            )
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadDDTWithAmounts(item) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadDDTWithAmounts(item.id, this.$store.state.user.locale)
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadDDT(item) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadDDT(item.id, this.$store.state.user.locale)
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
