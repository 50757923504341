<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="1024" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <messageBox ref="msgBox" />
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-format-list-text</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("pricelists-bulk-changes") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <div class="vertical-spacer" />
            <v-select
              :label="$t('pricelists-bulk-mode')"
              item-text="name"
              item-value="value"
              outlined
              dense
              v-model="mode_inclusive"
              :items="bulk_modes"
            />

            <v-row>
              <v-col>
                {{
                  mode_inclusive
                    ? "Applica la modifica ai listini selezionati"
                    : "Applica la modifica a tutti i listini ad eccezione di quelli selezionati"
                }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  :disabled="loadingPriceLists"
                  clearable
                  dense
                  no-filter
                  v-model="selector_pricelist"
                  :items="selector_pricelists"
                  :loading="isLoadingPriceLists"
                  :search-input.sync="searchPriceListInput"
                  outlined
                  item-text="name"
                  item-value="id"
                  :label="$t('pricelists-bulk-selector')"
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-card
              class="overflow-y-auto"
              max-height="300"
              v-if="selected_pricelists.length > 0"
            >
              <v-card-text>
                <v-row
                  v-for="pricelist in selected_pricelists"
                  :key="pricelist.id"
                  no-action
                >
                  <v-col cols="1">
                    <v-tooltip bottom
                      ><template v-slot:activator="{ on }"
                        ><v-btn
                          x-small
                          text
                          icon
                          color="red darken-1"
                          @click="deletePriceList(pricelist)"
                          v-on="on"
                          ><v-icon>mdi-delete</v-icon></v-btn
                        ></template
                      ><span>{{
                        $t("pricelists-bulk-remove-item")
                      }}</span></v-tooltip
                    >
                  </v-col>
                  <v-col class="text-left" cols="11">
                    {{ pricelist.name }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <div style="height:20px" />
            <v-row dense>
              <v-col col="1">
                <v-text-field
                  outlined
                  dense
                  style="max-width:200px"
                  :label="$t('pricelists-bulk-percent')"
                  v-model="increase_percent"
                >
                </v-text-field>
              </v-col>
              <v-col class="text-right">
                <v-btn :disabled="selected_pricelists.length == 0 && mode_inclusive" v-on:click="applyBulkChanges()" color="warning">{{
                  $t("pricelists-bulk-apply")
                }}</v-btn>
              </v-col>
            </v-row>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<style scoped>
.card-title-text {
  font-size: 1.5em;
}
</style>

<script>
import config from "../config";
import priceListsManager from "../apis/pricelists";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      bulk_modes: [
        {
          value: true,
          name: "Inclusivo",
        },
        {
          value: false,
          name: "Esclusivo",
        },
      ],
      mode_inclusive: true,
      loadingPriceLists: false,
      isLoadingPriceLists: false,
      selector_pricelist: null,
      selector_pricelists: [],
      selected_pricelists: [],
      searchPriceListInput: null,
      increase_percent: 1.0,
    };
  },

  components: {
    messageBox,
  },

  props: ["value"],

  mounted() {},

  watch: {
    selector_pricelist(val) {
      if (val) {
        this.selected_pricelists.push(val);
        this.selector_pricelist = null;
        this.selector_pricelists = [];
      }
    },

    searchPriceListInput(val) {
      clearTimeout(this._searchPriceListInput);
      if (val) {
        this._searchPriceListInput = setTimeout(() => {
          this.isLoadingPriceLists = true;
          let id_tofilter = this.selected_pricelists.map((x) => {
            return x.id;
          });
          this.fetchPriceListsFilter(val, id_tofilter)
            .then((res) => {
              this.selector_pricelists = res.pricelists;
              this.isLoadingPriceLists = false;
            })
            .catch(() => {
              this.isLoadingPriceLists = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        this.selector_pricelist = null;
      }
    },
  },

  methods: {
    fetchPriceListsFilter(filter, id_tofilter) {
      return new Promise((resolve, reject) => {
        priceListsManager
          .fetchPriceListsFilter(filter, id_tofilter)
          .then((results) => {
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    applyBulkChanges() {
      this.$refs.msgBox
        .show(
          this.$t("pricelists-bulk-confirm"),
          this.$t("pricelists-bulk-confirm-text")
        )
        .then(() => {
          this.$emit("bulkChangesConfirmed", {
            mode_inclusive: this.mode_inclusive,
            selected_pricelists: this.selected_pricelists,
            increase_percent: this.increase_percent,
          });
        })
        .catch(() => {});
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    resetModal() {
      this.mode_inclusive = true;
      this.selected_pricelists = [];
      this.increase_percent = 1.0;
    },

    deletePriceList: function(item) {
      this.selected_pricelists = this.selected_pricelists.filter((x) => {
        return x.id != item.id;
      });
    },
  },
};
</script>
