var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":"0.6","persistent":"","max-width":"800","value":_vm.value}},[_c('messageBox',{ref:"msgBox"}),_c('setString',{ref:"setString"}),_c('setStringWithSelect',{ref:"setStringWithSelect"}),_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-package-variant")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"25px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.$t("category-edit-title")))]),_c('v-col',{staticClass:"pa-0 text-right",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)]},proxy:true}])},[_c('v-card-text',[(true)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addCategory()}}},[_vm._v(_vm._s(_vm.$t("category-edit-add")))]):_vm._e(),_c('v-data-table',{attrs:{"items-per-page":_vm.perPage,"must-sort":true,"headers":_vm.headers,"items":_vm.categories,"options":_vm.options,"server-items-length":_vm.totalCategories,"loading":_vm.loading,"footer-props":_vm.dataTableFooterOptions},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(true)?_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e()]}},{key:"item.availability",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.staticData.availability[item.availability].name)+" "),(true)?_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editAvailability(item)}}},[_vm._v("mdi-pencil")]):_vm._e()]}},{key:"item.last_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.staticData.last_time[item.last_time].name)+" "),(true)?_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editLastTime(item)}}},[_vm._v("mdi-pencil")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(true)?_c('v-icon',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }